import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Provision Portal HowTo Guide`}</h1>
    <p>{`This will serve as a “How to” for Provision Portal. The goal is to communicate the innerworkings of Provision Portal, the AWS tooling, and the Automation Document execution.`}</p>
    <h2>{`Sections`}</h2>
    <ul>
      <li parentName="ul">{`Networking - Provision Portal networking overview`}</li>
      <li parentName="ul">{`AWS Services - Services/tools that Provision Portal leverages`}</li>
      <li parentName="ul">{`Automation Docs - AutoDoc 101`}</li>
      <li parentName="ul">{`Troubleshooting - How to troubleshoot issues and what to look for`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      